import React, { useState, useLayoutEffect, useRef, ReactNode, RefObject } from 'react'
import Carousel from 'react-multi-carousel'

// Components
import RightArrow from './RightArrow'
import LeftArrow from './LeftArrow'

// Hooks
import useWidth from '../../../../hooks/useWidth'

// Styles
import 'react-multi-carousel/lib/styles.css'
import { Container } from './style'

type CarouselBreakpoints = {
  items?: number;
  slidesToSlide?: number;
  partialVisibilityGutter?: number;
}

type DefaultCarouselProps = {
  children: ReactNode[];
  sm: CarouselBreakpoints;
  md: CarouselBreakpoints;
  lg: CarouselBreakpoints;
  xl: CarouselBreakpoints;
  removeArrowOnDeviceType?: string[];
  containerClass?: string;
  customRightArrow?: ReactNode;
  customLeftArrow?: ReactNode;
  notShowDots?: boolean;
  customDots?: ReactNode;
  notShowArrows?: boolean;
  beforeChange?: () => {};
}

const WIDTH_MD = 768
const WIDTH_LG = 992
const WIDTH_XL = 1200

function DefaultCarousel ({ children, sm, md, lg, xl, removeArrowOnDeviceType, containerClass, customRightArrow, customLeftArrow, notShowDots, customDots, notShowArrows, beforeChange }: DefaultCarouselProps) {
  const CarouselRef = useRef<Carousel | unknown>()
  const windowWidth = useWidth(300)
  const [ breakpoint, setBreakpoint ] = useState(sm)
  const CustomRightArrow: ReactNode = customRightArrow
  const CustomLeftArrow: ReactNode = customLeftArrow
  const NotShowDots: boolean | undefined = notShowDots
  const CustomDots: ReactNode | null = customDots
  const NotShowArrows: boolean | undefined = notShowArrows

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: xl.items || 4,
      slidesToSlide: xl.slidesToSlide || 1,
      partialVisibilityGutter: xl.partialVisibilityGutter || 0,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: lg.items || 3,
      slidesToSlide: lg.slidesToSlide || 1,
      partialVisibilityGutter: lg.partialVisibilityGutter || 0,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: md.items || 2,
      slidesToSlide: md.slidesToSlide || 1,
      partialVisibilityGutter: md.partialVisibilityGutter || 0,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: sm.items || 1,
      slidesToSlide: sm.slidesToSlide || 1,
      partialVisibilityGutter: sm.partialVisibilityGutter || 0,
    },
  }

  useLayoutEffect(() => {
    if (windowWidth >= WIDTH_XL) {
      setBreakpoint(xl)
    } else if (windowWidth >= WIDTH_LG && windowWidth < WIDTH_XL) {
      setBreakpoint(lg)
    } else if (windowWidth >= WIDTH_MD && windowWidth < WIDTH_LG) {
      setBreakpoint(md)
    } else {
      setBreakpoint(sm)
    }

    CarouselRef.current.goToSlide(0)
  }, [ windowWidth ])

  return (
    <Container>
      <Carousel
        ref={CarouselRef as RefObject<Carousel>}
        responsive={responsive}
        customRightArrow={CustomRightArrow ? <CustomRightArrow /> : <RightArrow />}
        customLeftArrow={CustomLeftArrow ? <CustomLeftArrow /> : <LeftArrow />}
        showDots={NotShowDots ? !NotShowDots : children.length > breakpoint.items}
        customDot={CustomDots ? <CustomDots /> : null}
        arrows={NotShowArrows ? !NotShowArrows : true}
        partialVisible={true}
        removeArrowOnDeviceType={removeArrowOnDeviceType}
        containerClass={containerClass}
        beforeChange={beforeChange}
      >
        {children}
      </Carousel>
    </Container>
  )
}

export default DefaultCarousel
